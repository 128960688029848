import React from 'react';
import '../styles/footer.css';

function Footer() {
  return (
    <footer>
      <p style={{background: 'transparent'}}>Made with love, the BAI Valentines Team: Velina & Omar &lt;3.</p>
    </footer>
  );
}

export default Footer;
